<template>

<div class='page' >
    <div class="content">
        <div class="viode" > 

 <div v-show="!isshow"  ><video  id="requiredVal1" :muted='muted'  ref="requiredVal1"  :autoplay="autoplay" controls :src="requiredValData.viode"></video></div>   
    <div class="viodebg"   v-show="isshow">
        <div class="" @click="pale">
            <img src="../assets/img/requiredVal22.png" alt="">

            

        </div>
        <!-- <div class="viodebgpaly">  <img src="../assets/img/bvofang.png" alt=""></div> -->
      

 

    </div>



        </div>
        <div class="rig">
            <div class="title">{{requiredValData.H1}}</div>
            <div class="model">
                <div class="item">
                    <div class="itemModel">
                        <div class="itemModelImg"><img src="../assets/img/poname.png" alt=""></div>
                        <div class="inp"><input placeholder="Name*" v-model="firstName" type="text" ></div>
                    </div>
                     <div class="itemModel">
                        <div class="itemModelImg"><img src="../assets/img/pophone.png" alt=""></div>
                        <div class="inp"><input placeholder="Phone*" v-model="phone" type="text" ></div>
                    </div>
                </div>
                 <div class="item">
                    <div class="itemModel1">
                        <div class="itemModelImg"><img src="../assets/img/poemail.png" alt=""></div>
                        <div class="inp"><input placeholder="Email*" v-model="email" type="text" ></div>
                    </div>
                     
                </div>
                 <div class="item">
                   
                     <div class="itemModel1">
                        <div class="itemModelImg"><img src="../assets/img/pomes.png" alt=""></div>
                         <div class="textareabg"> 
                                       <textarea  v-model="message" placeholder="Message" ></textarea>
                                      </div>
                    </div>
                </div>
                  <div class="item">
                    <div class="itemModel1">
                        <!-- <div class="itemModelImg"><img src="../assets/img/pofile.png" alt=""></div> -->
                        <uploadoss class="selectFile" v-on:getossdata="showossdataHead" title="Browse" v-on:getossurl="showossurlHead" url="projectreview/upload"></uploadoss>
                        <span class="fileName">{{fileName}}</span>
                    </div>
                    
                </div>
                 <div class="item">
                   <el-button :loading="isbool"  @click="submit"  >SUBMIT</el-button>

                    
                </div>
               
            </div>
        </div>

    </div>
</div>
</template>
<script>
 
import uploadoss from '../components/uploadoss.vue'

export default {
data() {
return {

 
muted:true,
   firstName:'',
    message:'',
    phone:'',
    email:'',
    file:'',

    isshow:true,

autoplay:'',


    fileName:'No file selected.',
    isbool:false,







    videosrc:'https://hlh-shopping.oss-cn-shenzhen.aliyuncs.com/vedio/2021/0512/z8ZJdPYAyy.mp4'
}
},
methods: {
    submit(){

            this.isbool=true
			if(this.firstName == '') {
				this.$message({
					message: 'Enter your name',
					type: 'warning'
				});
                  this.isbool=false
				return false
			}
			if(this.email == '') {
				this.$message({
					message: 'Enter your EMail',
					type: 'warning'
				});
                  this.isbool=false
				return false
			}
			if(this.phone == '') {
				this.$message({
					message: 'Enter your phone number',
					type: 'warning'
				});
				return false
			}
			this.$request.post('/projectreview/insert',{
				firstName:this.firstName,
				lastName:this.lastName,
				email:this.email,
                message:this.message,
				phone:this.phone,
				fileUpload:this.file,
			}).then((res)=>{
				if(res.code == 0) {
                    this.isbool=false
					this.$message({
						message: 'submit successfully',
						type: 'success'
					});
					this.firstName = '',
					this.email = '',
					this.phone = '',
					this.file = '',
                    this.message='',
					this.isRobot = false
                    
				}else{
                      this.isbool=false
                }
			}) 
    },
    
        showossdataHead(data) {
    
			this.fileName = data[0].name
        },
		showossurlHead(fileArr) {
      
			this.file = fileArr[0].path
		},
pale(){
    console.log('xxx');
    this.isshow=false

        document.getElementById('requiredVal1').play()
 this.muted=false
},

},
mounted() {
       document.getElementById('requiredVal1').addEventListener('ended', () => { //该视频播放是否已结束
              this.isshow=true
              this.muted=true
      }, false)
},
created() {},
props:{
    requiredValData:{ }
    },
watch:{ },
components:{
    uploadoss,
}
}
</script>
<style scoped>
.viode{
    max-width: 520px;
    width: 520px;
    min-width: 330px;
    /* height:347px ; */
    display: flex;
    align-items: center;
    /* height: 350px; */
    background: #000000;
       border-radius: 5px;
       border: 2px solid #B8B4B4;
       overflow: hidden;
 
    /* border: 2px solid #B8B4B4 */
    /* background: yellow */
}

.viodebg{
    width: 100%;
   
       cursor: pointer;
 
}
video{
    border-radius: 5px;
 
 width: 100%;
/* height: 100%; */
object-fit:fill;
}
.textareabg{
    display: flex;
                        width: 100%;
                         /* border: 1px solid #E5E5E5;   */
                         /* background: white; */

                         }
                              textarea{
                   line-height: 11px;
                    width: 100%;
                    padding: 10px 5px;
                    height: 31px;
                
                    border: none;
                    font-size: 13px;
                     font-family: 'WorkSans-Regular';
                }
                textarea::-webkit-input-placeholder {
                    /* WebKit browsers */
                    /* placeholder颜色  */
                    color: #757575;
                    /* placeholder字号  */
                    font-size: 13px;
                 
                  font-family: 'WorkSans-Regular';

                  }
                  textarea:-moz-placeholder {
                    /* Mozilla Firefox 4 to 18 */
                    color: #757575;
                    font-size: 13px;
                  }
                  textarea::-moz-placeholder {
                    /* Mozilla Firefox 19+ */
                    color: #757575;
                    font-size: 13px;
                  }
                  textarea::-ms-input-placeholder {
                    /* Internet Explorer 10+ */
                    color: #757575;
                    font-size: 13px;
                  }
button{
    height: 100%;
    background: #da251d;
     border:none;
    color: white;
    border-radius: 0px
}
button:hover{
      background: #da251d;

    color: white
}
.fileName{
    color: #999999;
    font-size: 13px
}
img{
    width: 100%;
    height: 100%;
}
.itemModelImg{
    width: 35px;
    height: 35px;
}
.itemModel{
    display: flex;
    padding: 5px 10px;
    background: white;
 
    width:48%;

}
.itemModel1{
    display: flex;
    padding: 5px 10px;
    line-height: 37px;
    background: white;
 
    width:100%;
}
  .selectFile {
                                display: inline-block;
                                width: 68px;
                                height: 22px;
                                background-color: #cccccc;
                                color: white;
                              
                                text-align: center;
                                font-size: 12px;
                                margin-right: 15px;
                                border-radius: 3px;
                                margin-top: 8px;
                            }
.title{
    width: 100%;
 
font-size: 27px;
font-family: Work Sans;
font-weight: 400;
color: #DA251D;
margin-bottom: 28px
}
  input {
                            width: 100%;
                            height: 100%;
                            border:none;
                            padding-left: 5px;
                            font-size: 14px;
                          
                            
                        }
.item{
    width: 100%;
    display: flex;
    justify-content: space-between;
 
    margin: 20px 0;
}
.rig{
    width: 610px;
    
}
.page{
    width: 100%;
    padding: 73px 0;
    /* background: black; */
    background: url('../assets/img/cncpwd2.png') no-repeat;
    /* background-size:100% 100% */
    background-position: center !important;
}
.content{
 width: 1200px;
 margin: auto;
 display: flex;
 justify-content: space-between;
 align-items: center
}

 @media screen and (max-width: 760px) {
       .page{
          background: url('../assets/img/cncpwd22.png') no-repeat;
          background-size: 100% 100%
    }
   .content{
        width: 90%;
        display: block
    }
    .title{
        margin: 30px 0;
        font-size: 16px
    }
    .viode{
        /* width: 520px; */
        width: 100%;
        /* max-width: ; */
        margin: auto
    }
    .item{
        display:block;
        
    }
    .itemModel{
        margin-bottom:20px ;
        width: 100%;
    }
  button{
        width: 100%;
    }
 .rig{
        width:100%;
    }
}
@media screen and (min-width: 760px) and (max-width: 1020px) {
   
    .page{
          background: url('../assets/img/cncpwd22.png') no-repeat;
          background-size: 100% 100%
    }
     
    .content{
        width: 90%;
        display: block
    }
    button{
        width: 100%;
    }
    .title{
        margin: 30px 0;
        font-size: 22px
    }
    .viode{
        width: 520px;
        margin: auto;
        height: 347px;
    }
    .rig{
        width:100%;
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .content{
        width: 90%;
    }
    .viode{
        width: 43%;
        height: 26vw;
    }
    .rig{
        width: 47vw;
    }

}
@media screen and (min-width: 1350px) {
    
    .viode{
        height: 347px;
    }
}









 
</style>